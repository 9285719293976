@use '../config/' as *;

.waf-shimmer.waf-component {
    //*************** listing shimmer ******************
    .article {
        &-item{
            box-shadow:.1rem .1rem .5rem var(--shimmer-dark)
        }
        &-thumbnail {
            .img-box {
                @include shimmer(null, null, var(--space-2));
            }
        }
        &-info{
            margin-bottom: var(--space-2);
            @include shimmer(100%, 2rem);
            
        }
        &-title {
            @include shimmer(50%, 2rem);
        }
        &-content{
            align-content: flex-start;
        }
        
    }
    .article-readmore,.reaction-section,.article-meta,.article-description,.item-type-icon,.timestamp,.tagline,.category{
        display: none;
    }
}