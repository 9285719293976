@use "../config/" as *;
.waf-component {
  &.waf-detail {
    @extend %px-0;
    @extend %my-0;
    @extend %mx-2-neg;
  }
}
.waf-detail {
  .tagline,
  .category .views-section {
    @extend %d-none;
  }
  .article-detail {
    @extend %px-3;
  }
  .title {
    font-weight: 700;
    @extend %neutral-900;
    @extend %pb-3;
    @extend %my-0;
    @extend %font-20;
  }
  .text,
  p,
  li {
    @extend %pb-2;
    @extend %mb-0;
    @extend %font-16;
  }
  .sub-title {
    font-weight: 700;
    @extend %neutral-900;
    @extend %my-0;
    @extend %pb-2;
    @extend %font-18;
  }
  .article-meta {
    @extend %my-0;
    @extend %flex-n-c;
  }
  .meta {
    &:not(:last-child) {
      &::after {
        content: "|";
        @extend %mx-2;
      }
    }
  }
  .meta-author {
    @extend %my-0;
  }
  .meta {
    @extend %neutral-900-7;
    @extend %font-12;
  }
  .head-wrap {
    @extend %mb-4;
    @extend %flex-sb-c;
  }
  video {
    @extend %w-100;
  }
  iframe {
    @extend %w-100;
  }
  .social {
    &-share {
      width: 3rem;
      height: 3rem;
      aspect-ratio: 1/1;
      @extend %relative;
      &.active {
        .social-share-wrap {
          @extend %flex;
        }
      }
      .share {
        &-icon {
          width: 3rem;
          aspect-ratio: 1/1;
        }
        &-label {
          @extend %d-none;
        }
      }
      .icon-b {
        &-share {
          &::before {
            @include icon(share);
          }
        }
        &-close {
          &::before {
            @extend %neutral-50;
            @include icon(close);
          }
        }
      }
      &-wrap {
        @include position-combo(tr);
        @extend %d-none;
      }
    }
    &-items {
      @extend %flex;
      @extend %neutral-50-bg;
    }
    &-icon {
      width: 3rem;
      aspect-ratio: 1/1;
      &::before {
        width: 3rem;
        height: 3rem;
        aspect-ratio: 1/1;
        font: 1.4rem $font-icon;
        @extend %neutral-50;
        @extend %flex-c-c;
      }
    }
    &-wrap {
      @extend %flex;
    }
    .close {
      width: 3rem;
      height: 3rem;
      aspect-ratio: 1/1;
      @extend %neutral-900-bg;
    }
  }
  .table {
    @extend %my-2;
  }
  .detail-title {
    font-weight: 700;
    @extend %neutral-900;
    @extend %my-0;
    @extend %pb-2;
    @extend %font-18;
  }
  .img-box {
    border-radius: 0;
  }
  .article-featured-media {
    @extend %mb-2;
  }
  .article-description {
    .text,
    p {
      @extend %font-16;
    }
  }
  .icon-copylink {
    &.active {
      &::after {
        width: max-content;
        left: 50%;
        transform: translate(-50%, -50%);
        @extend %ml-0;
      }
    }
  }
}
.detail-section {
  .container-wrap {
    @extend %mb-4;
  }
  img,
  video {
    @extend %half-radius;
  }
}
.related-listing {
  .waf-head {
    .title {
      @extend %neutral-900;
    }
  }
  .article-meta {
    .meta {
      @extend %neutral-50-8;
    }
  }
}
.waf-gallery-page {
  .article-gallery {
    &-list {
      @include grid((2), var(--space-4));
    }
    &-item {
      grid-row: span 2;
      @extend %relative;
      @extend %article-item-scale;
      &:nth-child(3n + 1) {
        grid-column: span 2;
      }
      .img-box {
        @extend %h-100;
        img {
          object-fit: cover;
          @extend %h-100;
        }
      }
      .btn-more {
        cursor: pointer;
        @extend %w-100;
        @extend %h-100;
        @extend %pos-tl;
      }
    }
  }
  .article-meta {
    &:first-child {
      @extend %pr-1;
    }
    .meta-count {
      order: 2;
      @extend %font-12-pb;
      @extend %neutral-900;
      @extend %pl-2;
      @extend %ml-1;
      &::before {
        content: '';
        @extend %pr-2;
        @extend %neutral-900;
        @include icon(ticket, 14);
      }
    }
  }
  .modal-window {
    height: var(--window-inner-height);
    z-index: var(--z-modal-detail);
    border: 0;
    flex-direction: column;
    justify-content: center;
    @include position-combo(inset, null, null, fixed);
    @extend %w-100;
    @extend %d-none;
    @extend %neutral-900-bg;
    .btn-close {
      @include position(var(--space-4), var(--space-4), null, null);
      &::after {
        @include icon(close, 16);
        @extend %neutral-50;
      }
    }
    .article {
      &-title {
        @include truncate(2, 16);
        height: auto;
        @extend %mb-0;
        @extend %px-4;
        @extend %text-center;
        @extend %neutral-50;
        @extend %flex-c-c;
      }
    }
    .item-type-icon,
    .article-description,
    .article-content {
      @extend %d-none;
    }
    .swiper-button {
      &-prev,
      &-next {
        cursor: pointer;
        width: 3rem;
        height: 3rem;
        right: var(--space-4);
        @extend %text-center;
        @extend %flex-c-c;
        @extend %neutral-50-bg;
        @extend %circle-radius;
        @extend %pos-y-center;
        &:before {
          content: "";
          @extend %font-12;
          @extend %neutral-900;
        }
        &.swiper-button-disabled {
          pointer-events: none;
          @extend %neutral-50-bg-4;
        }
      }
      &-prev {
        left: var(--space-4);
        &:before {
          @include icon(chevron-left);
        }
      }
      &-next {
        &:before {
          @include icon(chevron-right);
        }
      }
    }
    .swiper-pagination {
      @extend %neutral-50-5;
      @extend %font-14-pr;
      &-current {
        @extend %neutral-50;
        @extend %font-32-pb;
      }
    }
    .swiper-scrollbar {
      @extend %d-none;
    }
    .social-share {
      i {
        &:before {
          @extend %font-18;
          @extend %neutral-50-8;
        }
      }
    }
  }
  .modal {
    &-head {
      height: calc(20% - var(--space-10));
      @extend %mt-10;
    }
    &-footer {
      @extend %h-20;
      @extend %px-4;
      @include flex-config(flex, row-reverse, space-between, center);
    }
    &-body {
      @extend %h-60;
      @extend %flex-n-c;
      .preview-swiper {
        overflow: hidden;
        @extend %h-100;
      }
      .article-list {
        @extend %gap-0;
        @extend %h-100;
        @extend %flex-n-c;
        .article-item {
          flex-shrink: 0;
          @extend %flex-c-c;
          @extend %article-item-scale;
        }
        .article-wrap {
          @extend %w-100;
        }
      }
    }
  }
}
@include mq(col-md) {
  .waf-component {
    &.waf-detail {
      padding: var(--space-11) 0 0;
    }
  }
  .waf-detail {
    .article-detail {
      // max-width: calc(var(--container-max-width) - 25%);
      margin-inline: auto;
    }
    .title {
      font-size: 2.6rem;
      line-height: 3rem;
    }
    .sub-title {
      font-size: 2rem;
    }
    .text,
    p,
    li {
      font-size: 1.8rem;
      padding-bottom: var(--space-3);
    }
    .social {
      &-icon {
        margin-inline: var(--space-1);
        border-radius: 50%;
        // border: .1rem solid var(--neutral-900);
        &::before {
          color: var(--neutral-900);
        }
      }
      &-share {
        width: unset;
        height: unset;
        aspect-ratio: unset;
        .share {
          &-icon {
            display: none;
          }
        }
        .icon-b-share {
          &::before {
            content: unset;
          }
        }
        &-wrap {
          position: unset;
          display: flex;
        }
      }
      &-items {
        background: unset;
      }
      .close {
        display: none;
      }
    }
  }
  .related-listing {
    // max-width: calc(var(--container-max-width) - 25%);
    margin-inline: auto;
    .item-type-video {
      .item-type-icon {
        margin-bottom: 0;
        top: unset;
        bottom: var(--space-3);
        left: var(--space-3);
      }
    }
  }
}