@use "./config/" as *;
@forward "./main.scss";
.waf-governance {
    .title {
        @extend %font-20-pb;
        @extend %neutral-50;
    }
    .waf-head {
        @extend %mb-6;
    }
    .waf-body {
        @extend %neutral-50-bg;
        @extend %p-5;
        @extend %half-radius;
    }
    .primary-tab {
        @extend %my-3;
    }
    .tab-section {
        width: unset;
        border: 0;
    }
    .tabs {
        border: .1rem solid clr(secondary-100, 3);
        @include grid(2, 0);
        @extend %hidden;
        @extend %text-center;
        @extend %secondary-1000-bg;
        @extend %half-radius;
        @extend %px-0;
    }
    .tab-name {
        @extend %font-11-pr;
        @extend %uppercase;
        @extend %neutral-50;
        @extend %py-2;
        &.active {
            @extend %primary-500-bg;
            @extend %secondary-1000;
        }
    }
    .content {
        &-title {
            @extend %font-22-pb;
            @extend %secondary-1000;
            @extend %mb-3;
            @extend %uppercase;
        }
        &-description {
            line-height: 2.4rem;
            @extend %font-14-pr;
            @extend %mb-3;
        }
        &-wrap {
            @extend %mb-3;
            &:last-child {
                @extend %mb-0;
            }
        }
    }
    .pdf {
        &-tab {
            @include grid(1, var(--space-3));
        }
        &-text {
            @extend %font-12-pr;
        }
        &-tab-item {
            @extend %p-2;
            @extend %secondary-100-bg;
            @extend %w-100;
            @extend %neutral-1000;
            @extend %half-radius;
        }
        &-tab-wrap {
            @extend %flex-n-c;
            &::before {
                content: '';
                pointer-events: none;
                @include background(null, "svg/pdf-icon.svg", no-repeat);
                @include square(3rem);
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-governance {
        .waf-head {
            display: flex;
            align-items: center;
        }
        .content-title {
            font-size: 2.2rem;
        }
        .content-wrap {
            margin-bottom: var(--space-6);
        }
        .primary-tab {
            margin-block: 0;
        }
        .tabs {
            grid-template-columns: 10rem 10rem;
        }
        .tab-section {
            margin-left: auto;
        }
        .tab-name {
            padding-block: var(--space-1);
        }
        .pdf-tab {
            @include grid(4, var(--space-3));
        }
    }
}