@use "../config/" as *;
.waf-form-style {
  @extend %neutral-500-bg;
  &.waf-component {
    @extend %mx-2-neg;
  }
  p {
    @extend %mb-0;
  }
  .form {
    ::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: transparent;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: transparent;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: transparent;
    }
    flex-wrap: wrap;
    gap: var(--space-4) 0;
    @extend %flex-sb-n;
    &-group {
      &.mobile {
        @extend %d-block;
        @extend %relative;
        &::before {
          content: "";
          width: .1rem;
          @include position(0, null, null, calc(var(--space-3) + 7.2rem));
          @extend %h-100;
          @extend %neutral-300-bg;
        }
      }
    }
    &-element {
      border: .1rem solid var(--grey);
      @extend %h-100;
      @extend %p-2-3;
      @extend %half-radius;
      @extend %relative;
    }
    &-control {
      outline: none;
      border: 0;
      display: inline-block;
      background-color: transparent;
      @extend %w-100;
      @extend %h-100;
      &:focus {
        outline: unset;
      }
      &:not(:placeholder-shown) {
        @extend %pt-1;
        + .control-label {
          top: .2rem;
          color: var(--blue);
          font-size: 1rem;
          translate: unset;
        }
      }
    }
  }
  input {
    + .control-label {
      top: 50%;
      translate: 0 -50%;
    }
  }
  textarea {
    + .control-label {
      top: var(--space-2);
    }
  }
  .control-label {
    display: inline-block;
    font-size: 1.2rem;
    pointer-events: none;
    transition: all .3s linear;
    @include position(null, null, null, var(--space-3));
    @extend %neutral-500;
  }
  .mobile {
    .form-element {
      @extend %p-0;
    }
  }
  .mobile-number {
    @extend %flex;
    .select-box {
      flex-basis: 7rem;
      flex-shrink: 0;
      @extend %py-2;
      @extend %pl-3;
      @extend %flex-sb-c;
      @extend %relative;
    }
    &-wrapper {
      flex-grow: 1;
      @extend %p-2-3;
      @extend %relative;
    }
    .country-flag {
      width: 2.1rem;
      height: 1.4rem;
      flex-shrink: 0;
      object-fit: contain;
      @extend %neutral-300-bg;
    }
    .selected-label {
      width: 5rem;
      z-index: 1;
      font-size: 1.2rem;
      flex-grow: 1;
      text-align: center;
      cursor: pointer;
      @extend %relative;
      @extend %neutral-50-bg;
      @extend %text-center;
      &::after {
        content: "\e807";
        display: inline-block;
        font: 1rem/1 $font-icon;
        @extend %ml-1;
        @extend %neutral-300;
      }
    }
    select {
      width: 4rem;
      cursor: pointer;
      @include position-combo("tr");
      @extend %h-100;
      @extend %pt-0;
    }
  }
  .errordiv {
    @extend %d-none;
  }
  .checkbox-container {
    gap: var(--space-1);
    @extend %flex-n-c;
    @extend %relative;
    input {
      width: 1.6rem;
      height: 1.6rem;
      cursor: pointer;
      opacity: 0;
      @include position-combo("tl");
      &:checked {
        + .checkmark {
          border-color: var(--orange);
          &::after {
            @include flex-config(flex, null, center, center);
          }
        }
      }
    }
    .checkmark {
      width: 1.6rem;
      height: 1.6rem;
      border: .1rem solid var(--neutral-300);
      pointer-events: none;
      @extend %half-radius;
      &::after {
        content: "\e819";
        font: 1rem/1 $font-icon;
        @extend %w-100;
        @extend %h-100;
        @extend %d-none;
        @extend %neutral-900;
      }
    }
  }
  .password {
    .form-element {
      @extend %flex-n-c;
    }
    .tooltip {
      @extend %relative;
      &-text {
        width: 20rem;
        font-size: 1rem;
        line-height: 1.2rem;
        -webkit-box-shadow: 0 0 0.5rem 0 hsl(var(--hsl-neutral-900)/0.2);
        -moz-box-shadow: 0 0 .5rem 0 hsl(var(--hsl-neutral-900)/0.2);
        box-shadow: 0 0 0.5rem 0 hsl(var(--hsl-neutral-900)/0.2);
        @include position(null, 0, 100%);
        @extend %p-1-2;
        @extend %neutral-50-bg;
        @extend %d-none;
      }
    }
    .form-control {
      flex-grow: 1;
    }
    .show-password-icon {
      &::after {
        content: "\e81c";
        display: inline-block;
        font: 1.4rem/1 $font-icon;
        @extend %neutral-300;
      }
    }
    .btn-group {
      flex-shrink: 0;
      gap: var(--space-2);
      @extend %flex-n-c;
    }
    .show-tooltip {
      &[aria-expanded='true'] {
        ~ .tooltip-text {
          @extend %d-block;
        }
      }
    }
    .show-tooltip-icon {
      &::after {
        content: "\e817";
        display: inline-block;
        font: 1.4rem/1 $font-icon;
        @extend %neutral-300;
      }
    }
  }
  .global-error {
    @extend %d-none;
  }
  .btn-fill {
    padding: var(--space-2) var(--space-4);
    border: 0;
    border-radius: .3rem;
    @extend %neutral-700;
    .btn-text {
      font: 700 1.4rem/1 $font-primary;
      @extend %neutral-50;
    }
  }
}